import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompareComponent } from './components/compare/compare.component';
import { DealerinfoComponent } from './components/dealerinfo/dealerinfo.component';
import { DealersComponent } from './components/dealers/dealers.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { FinanceApplicationComponent } from './components/financeapplication/financeapplication.component';
import { HomeComponent } from './components/home/home.component';
import { NewsComponent } from './components/news/news.component';
import { NewsdetailComponent } from './components/news/newsdetail/newsdetail.component';
import { PartsComponent } from './components/parts/parts.component';
import { PricecompareComponent } from './components/pricecompare/pricecompare.component';
import { SellComponent } from './components/sell/sell.component';
import { ServiceComponent } from './components/service/service.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { WhyserviceComponent } from './components/whyservice/whyservice.component';
import * as path from 'path';
import { CookiesComponent } from './components/cookies/cookies.component';
import { AlertlinksComponent } from './components/alertlinks/alertlinks.component';
import { FleetContactUsComponent } from './components/fleetcontactus/fleetcontactus.component';

const routes: Routes = [

  {
    path: '',
    component: HomeComponent,
    data: { Module: 'Home', Title: 'Home' }
  },
  {
    path: 'buy',
    loadChildren: () => import('./components/buy/buy.component').then(m => m.BuyModule),
    //component: BuyComponent,
    pathMatch: 'full',
    data: { Module: 'Buy', Title: 'Buy' }
  },
  {
    path: 'buy/:seo',
    loadChildren: () => import('./components/buy/buy.component').then(m => m.BuyModule),
    //component: BuyComponent,
    pathMatch: 'full',
    data: { Module: 'Buy', Title: 'Buy' }
  },
  {
    path: 'dealership/:id',
    component: DealerinfoComponent,
    data: { Module: 'Contact Us', Title: 'Contact Us' }
  },
  {
    path: 'compare',
    
    loadChildren: () => import('./components/compare/compare.component').then(m => m.CompareModule),
    pathMatch: 'full',
    data: { Module: 'Compare', Title: 'Compare' }
  },
  {
    path: 'newvehicles/:id/:name',
    redirectTo: 'buy/new/:id/:name',
    
  },
  {
    path: 'preowned/:id/:name',
    redirectTo: 'buy/stock/:id/:name',
    
  },
  {
    path: 'democars/:id/:name',
    redirectTo: 'buy/stock/:id/:name',
    
  },
  {
    path: 'specials/:id',
    redirectTo: 'buy/special/:id/special',
    
  },
  {
    path: 'promo/:id',
    redirectTo: 'buy/promo/:id',
    
  },
  {
    path: 'buy/:type/:id/:name',
    loadChildren: () => import('./components/buy/details/details.component').then(m => m.DetailsModule),
    pathMatch: 'full',
    data: { Module: 'Buy', Title: 'Buy' }
  },
  {
    path: 'buy/:type/:id',
    loadChildren: () => import('./components/buy/details/details.component').then(m => m.DetailsModule),
    pathMatch: 'full',
    data: { Module: 'Buy', Title: 'Buy' }
  },
  {
    path: 'sell',
    loadChildren: () => import('./components/sell/sell.component').then(m => m.SellModule),
    data: { Module: 'Sell My Car', Title: 'Sell My Car' }
  },
  {
    path: 'parts',
    component: PartsComponent,
    data: { Module: 'Parts And Service', Title: 'Parts' }
  },
  {
    path: 'service',
    component: ServiceComponent,
    data: { Module: 'Service Online', Title: 'Service Online' }
  },
  {
    path: ':type/thankyou',
    component: ThankyouComponent,
    data: { Module: 'Thank You', Title: 'Thank You' }
  },
  {
    path: 'financeapplication',
    component: FinanceApplicationComponent,
    data: { Module: 'Finance Application', Title: 'Finance Application' }
  },
  {
    path: 'fleetcontactus',
    component: FleetContactUsComponent,
    data: { Module: 'Fleet Contact Us', Title: 'Fleet Contact Us' }
  },
  {
    path: 'whyservice',
    component: WhyserviceComponent,
    data: { Module: 'Why Service', Title: 'Why Service' }
  },
  {
    path: 'favourites',
    component: FavouritesComponent,
    data: { Module: 'Favourites', Title: 'Favourites' }
  },
  {
    path: 'pricecompare/:id',
    component: PricecompareComponent,
    data: { Module: 'Compare', Title: 'Compare' }
  },
  {
    path: 'dealerships',
    component: DealersComponent,
    data: { Module: 'Dealer Locator', Title: 'Dealerships' }
  },
  {
    path: 'news',
    component: NewsComponent,
    data: { Module: 'News', Title: 'News' }
  },
  {
    path: 'cookies',
    component: CookiesComponent,
    data: { Module: 'Cookie Policy', Title: 'Cookie Policy' }
  },
  {
    path: 'verifyalert',
    component: AlertlinksComponent
  },
  {
    path: 'alertoptout',
    component: AlertlinksComponent
  },
  {
    path: 'news/:id/:title',
    component: NewsdetailComponent,
    data: { Module: 'News', Title: 'News' }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
