<div class="dealerinfo">
    <div class="row">
        <div class="col-12 col-md-4 imgcontainer">
            <img loading="lazy" *ngIf="this.Dealer.Image" src="{{this.Dealer.Image}}" alt="">
            <img loading="lazy" *ngIf="!this.Dealer.Image" src="{{site?.vehicleImages?.Value}}" alt="">
        </div>
        <div class="col-12 col-md-4 paddingTop">
            <h4>{{this.Dealer.Name}}</h4>
            <app-reviews [reviewnumber]="Dealer.GoogleRatingReviews"
                [reviewscore]="Dealer.GoogleRatingScore"></app-reviews>
            <a *ngIf="this.Dealer.NewDealer" href="/buy?type=new&dealership={{this.Dealer.DealershipID_PK}}"><button class="btn-primary">View New
                    Models</button></a>
            <a *ngIf="this.Dealer.UsedDealer" href="/buy?type=used&dealership={{this.Dealer.DealershipID_PK}}"><button class="btn-primary">View Used
                    Models</button></a>
            <a href="/buy?type=specials&dealership={{this.Dealer.DealershipID_PK}}"><button *ngIf="this.site?.NumberOfSpecials > 0" class="btn-primary">View
                    Specials</button></a>
            <a *ngIf="this.Dealer.ServiceOnlineLink && this.Dealer.ServiceDealer" target="_blank" href="{{this.Dealer.ServiceOnlineLink}}"><button class="btn-primary">Book a
                    Service Online</button></a>
            <a *ngIf="!this.Dealer.ServiceOnlineLink" href="/service?dealership={{this.Dealer.DealershipID_PK}}"><button class="btn-primary">Book a
                    Service Online</button></a>
            <button *ngIf="site?.layoutFeatures?.includes('contactus')" (click)="enquire()" class="col-4 btn-primary">Contact Us</button>
        </div>
        <div class="col-12 col-md-4 paddingTop">
            <h5>Contact Details & Trading Hours</h5>
            <p>Switch Board: {{this.Dealer.PhoneNumber}}</p>
            <p>Address: {{this.Dealer.AddressPhysicalStreet}}, {{this.Dealer.AddressPhysicalCity}}</p>
            <div class="mb-3" *ngFor="let dep of dealerDepartment">
                <p class="hourhead"><span *ngIf="dep.Department.toLowerCase() != 'general'">{{dep.Department}} Department</span> Trading Hours:</p>
                <div class="col-12 row" *ngFor="let hours of dep.BusinessHours"><div class="col-6">{{hours.Day}}:&nbsp;</div><div class="col-6">{{hours.Time}}</div></div>
            </div>
            <a href="https://maps.google.com?saddr=Current+Location&daddr={{this.Dealer.Location}}"
                target="_blank"><button class="btn-primary">Get Google Map Directions</button></a>
            <a *ngIf="this.Dealer.WebAddress && site?.NumberOfDealerships > 1" href="{{this.Dealer.WebAddress}}" target="_blank"><button
                    class="btn-primary">Visit Dealer Website</button></a>
        </div>
        <div *ngIf="TeamDetailList?.length > 0" class="container-fluid teammemberList">
            <div class="col-12 text-center">
                <h5 class="team-member-heading">Meet Our Team Members</h5>
                <h6><strong>Our {{this.Dealer.Name}} Team</strong></h6>
            </div>
            <div class="row flex-row flex-nowrap">
                <drag-scroll [snap-offset]="0" #badges [snap-disabled]="true" >
                    <div class="item" drag-scroll-item *ngFor="let t of TeamDetailList; index as i">
                    <div class="card" >
        
                        <div class="card-img-top image"><img loading="eager" class="d-block w-10"
                                    src="{{imgadd(t.ImageUrl)}}" alt="{{t.ImageURL}}"></div>
                        <div class="card-body">
                            <div><strong class="team-member-name">{{t.FirstName}} {{t.LastName}}</strong></div>
                            <div class="jobtitle">{{t.JobTitle}}</div>
                        </div>
                    </div>
                    </div>
                </drag-scroll>
            </div>
            <button aria-label="Prev" class="prev arrow" [ngClass]="hideArrow" type="button" (click)="prev()">
                <span class="carousel-control-prev-icon"></span>
            </button>
            <button aria-label="Next" class="next arrow" [ngClass]="hideArrow" type="button" (click)="next()">
                <span class="carousel-control-next-icon"></span>
            </button>
        </div>
    </div>  
    <div class="map" id="map"></div>
    <div *ngIf="Specials.length > 0" class="row specials">
        <div class="col-12 col-md-3" *ngFor="let special of Specials">
            <div><a href="buy/special/{{special.SpecialID_PK}}/{{urlsan(special.Caption)}}"><img loading="lazy" src="{{special.Image}}"
                        alt=""></a></div>
        </div>
    </div>

</div>