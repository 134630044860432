import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';
import { CampaignsService } from 'src/app/services/campaigns.service';

@Component({
  selector: 'app-financeapplication',
  templateUrl: './financeapplication.component.html',
  styleUrls: ['./financeapplication.component.less']
})
export class FinanceApplicationComponent implements OnInit {
  loading: boolean = false;
  banners: any[] = [];
  Titles: any = [];
  failedsubmit: boolean = false;
  Regions: any = [];
  Dealerships: any = [];
  formFinance!: FormGroup;
  siteid: any;
  siteName: any;
  currentSite: any;
  FinanceOverride: any;
  Texts: any;
  enquiryType: any;
  constructor(private formBuilder: FormBuilder, private apiService: GearsApiService, private siteService: SitesService, private campaignService: CampaignsService) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.formFinance = this.formBuilder.group({
      dealer: [-1, Validators.min(0)],
      title: [-1],
      region: [-1],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      mail: [null, [Validators.required, Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      phone: [null, [Validators.required, Validators.pattern(/^(?:[\+][0-9])?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/)]],
      marketing: [false],
      notes: [null]
    });

    this.siteService.get().then((x: any) => {
      this.siteid = x.SiteID_PK;
      this.siteName = x.container.Name;
      this.currentSite = x;
      this.FinanceOverride = this.currentSite.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'global')return s.PageLayoutType})[0]?.PageLayoutType
        ?.find((data:any) => data.PageLayoutTypeName == 'financeoverride');

      this.Texts = this.currentSite.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'global')return s.PageLayoutType})[0]?.PageLayoutType
        ?.find((data:any) => data.PageLayoutTypeName == 'financeoverride')?.Value;

      this.apiService.get('Banners', {
        SiteID_FK: this.siteid,
        Module: 'Finance',
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {

        this.banners = x.body;
        this.loading = false;
      });

      this.apiService.get('Dealerships', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: '',
        SiteID_FK: this.siteid
      }, true).then((x: any) => {
        this.Dealerships = x.body;
      });

    });
    this.apiService.get('Regions', {
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: ''
    }).then((x: any) => {
      this.Regions = x.body;
    });

    this.apiService.get('Titles', {
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: ''
    }).then((x: any) => {
      this.Titles = x.body;
    });

    this.apiService.get('EnquiryTypes',{
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: '',
      Active: 1,
      Deleted: 0
    }).then((x:any) => {
      this.enquiryType = x.body;
    })
  }

  submit() {
    var post;
    if (this.formFinance.invalid) {
      this.failedsubmit = true;
    }
    else if (!this.FinanceOverride) {
      post = {
        Active: 1,
        ContactNo: this.formFinance.controls['phone'].value,
        DealershipID_FK: this.formFinance.controls['dealer'].value,
        Deleted: 0,
        Email: this.formFinance.controls['mail'].value,
        EnquiryTypeID_FK: 1,
        Marketing: this.formFinance.controls['marketing'].value,
        Name: this.formFinance.controls['name'].value,
        SiteID_FK: this.siteid,
        Surname: this.formFinance.controls['surname'].value,
        UtmCampaign: this.campaignService.getGoogleTracking()
      }

      this.apiService.post('FinanceApplication', post).then((x: any) => {
        if (x.FinanceApplicationID_PK !== null) {
          var form = document.createElement("form");
          form.setAttribute("method", "post");
          form.setAttribute("target", "_blank");
          form.setAttribute("action", environment.SignioURL);

          var thirdPartyVendorCode = document.createElement("input");
          thirdPartyVendorCode.setAttribute("name", "thirdPartyVendorCode");
          thirdPartyVendorCode.setAttribute("value", this.Dealerships.find((x: any) => x.DealershipID_PK == this.formFinance.controls['dealer'].value).SignioVendorCode);

          var forwardingURL = document.createElement("input");
          forwardingURL.setAttribute("name", "forwardingURL");
          forwardingURL.setAttribute("value", window.location.protocol + '//' + window.location.hostname + '/financeapp/return/' + x.FinanceApplicationID_PK);

          var AuthToken = document.createElement("input");
          AuthToken.setAttribute("name", "AuthToken");
          AuthToken.setAttribute("value", environment.SignioAuthToken);

          var Authorization = document.createElement("input");
          Authorization.setAttribute("name", "Authorization");
          Authorization.setAttribute("value", btoa(environment.SignioAuth) );

          var customerFirstName = document.createElement("input");
          customerFirstName.setAttribute("name", "customerFirstName");
          customerFirstName.setAttribute("value", this.formFinance.controls['name'].value);

          var customerSurname = document.createElement("input");
          customerSurname.setAttribute("name", "customerSurname");
          customerSurname.setAttribute("value", this.formFinance.controls['surname'].value);

          var customerEmail = document.createElement("input");
          customerEmail.setAttribute("name", "customerEmail");
          customerEmail.setAttribute("value", this.formFinance.controls['mail'].value);

          var customerMobilePhoneNumber = document.createElement("input");
          customerMobilePhoneNumber.setAttribute("name", "customerMobilePhoneNumber");
          customerMobilePhoneNumber.setAttribute("value", this.formFinance.controls['phone'].value);

          var referrerName = document.createElement("input");
          referrerName.setAttribute("name", "referrerName");
          referrerName.setAttribute("value", window.location.hostname);


          form.appendChild(thirdPartyVendorCode);
          form.append(forwardingURL);
          form.append(AuthToken);
          form.append(Authorization);
          form.append(customerFirstName);
          form.append(customerSurname);
          form.append(customerEmail);
          form.append(customerMobilePhoneNumber);
          form.append(referrerName);



          document.body.appendChild(form);
          form.submit();        

          window.location.href = "/financeapplication/thankyou";
        }
      });
    }
    else {
      post = {
        Active: 1,
        ContactNo: this.formFinance.controls['phone'].value,
        DealershipID_FK: this.formFinance.controls['dealer'].value,
        Deleted: 0,
        Email: this.formFinance.controls['mail'].value,
        Marketing: this.formFinance.controls['marketing'].value,
        Name: this.formFinance.controls['name'].value,
        Notes: this.formFinance.controls['notes'].value || '',
        SiteID_FK: this.siteid,
        TitleID_FK: this.formFinance.controls['title'].value,
        Surname: this.formFinance.controls['surname'].value,
        EnquiryTypeID_FK: this.enquiryType.find(
          (x: any) => x.Code == 'Finance'
        ).EnquiryTypeID_PK,
      };

      this.apiService.post('Enquiries', post).then((x: any) => {
        if (x.EnquiryID_PK !== null) {
          window.location.href = "/financeapplication/thankyou";
        }
      });
    }
  }

}
