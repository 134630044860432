import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { ModalService } from 'src/app/modules/modalservice.service';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dealerinfo',
  templateUrl: './dealerinfo.component.html',
  styleUrls: ['./dealerinfo.component.less'],
})
export class DealerinfoComponent implements OnInit {
  Dealer: any = {};
  dealerid: any;
  Specials: any[] = [];
  mapLoaded = false;
  site: any;
  TeamDetailList: any;
  hideArrow: any = null;
  dealerDepartment:any;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private apiservice: GearsApiService,
    private route: ActivatedRoute,
    private siteService: SitesService,
    private modalservice: ModalService,
  ) {}
  @ViewChild('badges', { read: DragScrollComponent }) ds!: DragScrollComponent;
  ngOnInit(): void {
    this.dealerid = this.route.snapshot.paramMap.get('id')!;
    //this.route.queryParams.subscribe(params => { this.dealerid = params['dealership'] || [] });
    this.apiservice
      .getSingle('Dealerships', this.dealerid)
      .then((x: any) => {
        this.Dealer = x.body;

        this.siteService.get().then((s: any) => {
          this.getBusinessHours();
          this.apiservice
            .get('BusinessHours', {
              DealershipID_FK: this.Dealer.DealershipID_PK,
              OrderBy: 'Order.ASC'//,
              //SiteID_FK: s.SiteID_PK,
            })
            .then((b: any) => {
              let ValidBusinessHours: any[] = [];
              b.body.forEach((element:any) => {
                if (ValidBusinessHours.length === 0){
                  ValidBusinessHours.push(element);
                }
                if (!ValidBusinessHours.find(x => x.Day == element.Day) || 
                
                !ValidBusinessHours.find(x => x.Time == element.Time)){
                  ValidBusinessHours.push(element);
                };
              });
              this.Dealer.BusinessHours = ValidBusinessHours;
            });
          this.site = s;
          this.loadJsScript();
          this.apiservice
            .get('Specials', {
              DealershipID_FK: x.body.DealershipID_PK,
              OrderBy: 'GroupSpecial.ASC',
              PageSize: 4,
              SiteID_FK: s.SiteID_PK,
            })
            .then((s: any) => {
              this.Specials = s.body;
            });
          this.apiservice
            .get('SiteModules',{
              SiteID_FK: s.SiteID_PK,
              PageSize: 10,
              Orderby: '',
              Active: true,
              Deleted: false
            })
            .then((m:any)=>{
              if (m.body.findIndex((x:any) => x.Code == 'meettheteam') > -1){
                this.apiservice
                  .get('TeamDetails', {
                    SiteID_FK: s.SiteID_PK,
                    DealershipID_FK: x.body.DealershipID_PK,
                    PageSize: 10,
                    OrderBy: 'Order.ASC',
                    Active: true,
                    Deleted: false
                  })
                  .then((t:any) => {
                    this.TeamDetailList = t.body;
                    this.hideArrow = this.TeamDetailList.length < 6 ? 'hideArrow' : null
                  })
              }
            })
        });
      });
  }

  getBusinessHours(){
    this.apiservice.get('Departments',{
      Active: 1,
      DealershipID: this.Dealer.DealershipID_PK,
      Deleted: 0,
      OrderBy: 'Order.ASC',
      PageNumber: 1
    }).then((x:any)=>{
      this.dealerDepartment = x.body;
      if (this.dealerDepartment && this.dealerDepartment.length != 0) {
        for (let i = 0; i < this.dealerDepartment.length; i++){
          this.apiservice.get('BusinessHours', {
            Active: 1,
            Deleted: 0,
            DepartmentID_FK: this.dealerDepartment[i].DepartmentID_PK,
            OrderBy: 'Order.ASC',
            PageNumber: 1,
            PageSize: 9999
          }).then((bh:any)=>{
            this.dealerDepartment[i].BusinessHours = bh.body;
          })
        }
      }
    })
  }

  public mapLoad() {
    this.mapLoaded = true;
  }

  urlsan(url: any) {
    return url
      .replaceAll('+', '')
      .replaceAll('/', '')
      .replaceAll('\\', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(' ', '_');
  }

  loadJsScript() {
    if (isPlatformBrowser(this.platformId)) {
      const loader = new Loader({
        apiKey: this.site.GoogleMapsKey,
        version: 'weekly',
      });

      loader.load().then(async () => {
        const position = {
          lat: Number.parseFloat(this.Dealer.Location.split(',')[0]),
          lng: Number.parseFloat(this.Dealer.Location.split(',')[1]),
        };
        const map = new google.maps.Map(
          document.getElementById('map') as HTMLElement,
          {
            center: position,
            zoom: 14,
          }
        );
        const contentString =
          '<div id="content">' +
          '<div id="siteNotice">' +
          '</div>' +
          '<h5 id="firstHeading" class="firstHeading" style="font-size:1rem">' +
          this.Dealer.Name +
          '</h5>' +
          '<div id="bodyContent">' +
          (this.Dealer.PhoneNumber
            ? '<a class="call" href="tel:' +
              this.Dealer.PhoneNumber +
              '"><p style="margin-bottom: 2px;">Tel: ' +
              this.Dealer.PhoneNumber +
              '</p></a>'
            : '') +
          '<p style="margin-bottom: 2px;">Address: ' +
          this.Dealer.AddressPhysicalStreet + ', ' +
          this.Dealer.AddressPhysicalCity +
          '</p>' +
          '<a href="https://maps.google.com?saddr=Current+Location&daddr=' +
          this.Dealer.Location +
          '" target="_blank">Get Google Map Directions</a>' +
          (this.Dealer.WebAddress
            ? '<p style="margin-bottom: 2px;">Website: ' +
              this.Dealer.WebAddress +
              '</p>'
            : '') +
          '</div>' +
          '</div>';

        const infowindow = new google.maps.InfoWindow({
          content: contentString,
          ariaLabel: this.Dealer.Name,
        });
        const marker = new google.maps.Marker({
          map: map,
          position: position,
          title: this.Dealer.Name,
        });
        infowindow.open({
          anchor: marker,
          map,
        });
        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
        this.mapLoaded = true;
      });
    }
  }

  enquire() {
    var data = {
      Name: "Contact Us",
      Dealer: this.dealerid,
      Type: "ContactUs",
      Department: this.site?.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'features')return s.PageLayoutType})[0]
      ?.PageLayoutType.find((PageLayoutType:any) => PageLayoutType.PageLayoutTypeName == 'contactus')?.Value ?? undefined
    };
    this.modalservice.open('enquire', data);
  }

  imgadd(url: string) {
    if (url){
      if (!url.includes('http')) {
        return environment.storageurl + url;
      }
      else {
        return url;
      }
    }
    else {
      return this.site.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'vehicleimages')return s.PageLayoutType} )[0]?.PageLayoutType?.find((data:any) => data.PageLayoutTypeName == 'teamavatar')?.Value ?? this.site.vehicleImages.Value;
    }

  }
  next() {
    this.ds.moveRight();
  }

  prev() {
    this.ds.moveLeft();
  }
}
