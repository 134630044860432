export const environment = {
  production: true,
  dissableCache:false,
  // This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

testsite:[{testurl:['mccarthytoyota.mccarthy.co.za' ], dataurl:'www.mccarthytoyota.co.za'},{testurl:['mccarthytoyotaempangeni.mccarthy.co.za' ], dataurl:'theglen.mccarthyford.co.za'}, {testurl:['mccarthyford2023.mccarthy.co.za' ], dataurl:'www.mccarthyford.co.za'},{testurl:['mccarthynissan2023.mccarthy.co.za' ], dataurl:'www.mccarthynissan.co.za'},{testurl:['dealer2023.mccarthy.co.za' ], dataurl:'melrose.mccarthynissan.co.za'},{testurl:['mccarthyvw2023.mccarthy.co.za' ], dataurl:'www.mccarthyvw.co.za'},{testurl:['mccarthyvwarcadia2023.mccarthy.co.za' ], dataurl:'arcadia.mccarthyvw.co.za'}],
  //testsite: ['mccarthytoyota.mccarthy.co.za','www.mccarthytoyota.co.za'],
  //testsite:'mccarthylexus.elianceprelive.co.za',
  //production: false,
  GearsApi_url:'https://api.gearsauto.co.za/mccarthy/',
  //GearsApi_url: 'https://gearsapi.elianceprelive.co.za/',
  //GearsApi_url:'http://localhost:5874/',
  storageurl: "https://Gearscdn.azureedge.net/gears-live/",
  initfee: 500,
  adminfee: 1500,
  API_ID: "CSE",
  API_KEY: "a579263139d743fba868ed97f873a01c9f58f605c004439b9b859b17f63022f6",
  //API_URL: "gearsapi.elianceprelive.co.za",
  
  API_URL: "api.gearsauto.co.za/mccarthy",
  SignioURL: 'https://thirdparty.signio.co.za/ThirdPartyIntegration/application', 
  SignioAuth: 'britehouse:==39_follow_TOTAL_attempt_61==',
  SignioAuthToken: 'a030c6c7-9bdb-4f5a-be9d-c32211176ceb',
  FinanceInterest:11,
  FinanceMonths:72,
  FinanceDeposit:10,
  FinanceBalloon:10,

  NewsRedirect:null,
  facebookAppId: '731309600573232'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

