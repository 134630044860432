import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.less']
})
export class ContentComponent implements OnInit {
  @Input() Text = 'base';
  @Input() Image = '';
  constructor() { }

  ngOnInit(): void {
  }

}
