import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { CampaignsService } from 'src/app/services/campaigns.service';

import { ModalService } from 'src/app/modules/modalservice.service';

import { Console } from 'console';
import { generate } from 'rxjs';
@Component({
  selector: 'app-enquire',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './enquire.component.html',
  styleUrls: ['./enquire.component.less']
})
export class EnquireComponent implements OnInit {


  @Input() Data: any;
  Regions: any = [];
  Titles: any = [];
  site: any = {};
  formEnquire!: FormGroup;
  failedsubmit: boolean = false;
  submitloading = false;
  Dealerships!: any[];
  EnquiryTypes: any[] = [];
  showDepartment: boolean = false;
  currencyCode: any;
  departmentList = [
    { Name: "New cars", Code: "Vehicle" },
    { Name: "Mastercars", Code: "Pre-Owned" },
    { Name: "Service", Code: "ServiceBooking" },
    { Name: "Parts", Code: "Parts" }
  ]
  constructor(
    private modalService: ModalService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder,
    private siteService: SitesService,
    private el: ElementRef,
    @Inject(DOCUMENT) private _document: Document,
    private renderer: Renderer2,
    private apiservice: GearsApiService,
    private campaignService: CampaignsService
  ) {
  }
  ngOnInit(): void {
    this.formEnquire = this.formBuilder.group({
      dealer: [-1, Validators.min(0)],
      title: [null],
      region: [null],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      email: [null, [Validators.required, Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      phone: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^(?:[\+][0-9])?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/
          ),
        ],
      ],
      department: ['ContactUs'],
      marketing: [false],
      notes: [null],
      tcs: [false, Validators.requiredTrue],
    });

    this.siteService.get().then((x: any) => {
      this.site = x;
      this.currencyCode = this.site.currencyCode;
    });
    this.apiservice
      .get('Titles', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: '',
      })
      .then((x: any) => {
        this.Titles = x.body;
      });





    this.showDepartment = this.Data.Type == 'ContactUs' ? true : false;
    this.Dealerships = [];
    if (this.Data.Dealer == null) {
      if (this.Data.Type == 'specials') {
        this.apiservice.getSingle('Specials', this.Data.ID).then((special: any) => {
          /* if(special.body.GroupSpecial){
             this.apiservice
         .get(
           'Dealerships',
           {
             PageSize: 9999,
             PageNumber: 1,
             OrderBy: '',
             SiteID_FK: this.site.SiteID_PK,
           },
           true
         )
         .then((x: any) => {
           this.Dealerships = x.body;
           if (this.Dealerships.length == 1) {
             this.formEnquire.controls['dealer'].setValue(
               this.Dealerships[0].DealershipID_PK
             );
           }
           this.Dealerships.forEach((x: any) => {
             if (
               this.Regions.findIndex(
                 (y: any) => y.RegionID_PK == x.RegionID_FK
               ) < 0
             )
               this.Regions.push({
                 Name: x.Region,
                 RegionID_PK: x.RegionID_FK,
               });
           });
           this.Regions.sort((a: any, b: any) => {
             if (a.Name < b.Name) {
               return -1;
             }
             if (a.Name > b.Name) {
               return 1;
             }
             return 0;
           });
         });
           }
           else{*/

          this.siteService.get().then((site: any) => {
            if(site.dealerSite){
              this.Dealerships = [];
              this.Dealerships.push(site.dealership)
              this.formEnquire.controls['dealer'].setValue(
                site.dealership.DealershipID_PK
              );
            }
            else{
            this.apiservice.get('SiteSpecials', {
              PageNumber: 1,
              PageSize: 9999,
              OrderBy: 'Order.ASC',
              Deleted: 0,
              Active: 1,
              SpecialID_FK: this.Data.ID,
              //DateEnd: moment().startOf('day').add(2, 'hours').format('YYYY-MM-DD'),
              //SiteID_FK: site.SiteID_PK
            }).then((x: any) => {
              var SiteSpecialsList = x.body;

              SiteSpecialsList.forEach((item: any) => {
                if (item?.DealershipID_FK !== null) {
                  this.apiservice.getSingle('Dealerships', item.DealershipID_FK).then((dealer: any) => {

                    if (!this.Dealerships.find(function (listdealer) { if (listdealer.DealershipID_PK == dealer.body.DealershipID_PK) { return listdealer; } })) {
                      this.Dealerships.push(dealer.body);
                      this.Dealerships.forEach((x: any) => {
                        if (
                          this.Regions.findIndex(
                            (y: any) => y.RegionID_PK == x.RegionID_FK
                          ) < 0
                        )
                          this.Regions.push({
                            Name: x.Region,
                            RegionID_PK: x.RegionID_FK,
                          });
                      });
                      this.Regions.sort((a: any, b: any) => {
                        if (a.Name < b.Name) {
                          return -1;
                        }
                        if (a.Name > b.Name) {
                          return 1;
                        }
                        return 0;
                      });
                      if (this.Dealerships.length == 1) {
                        this.formEnquire.controls['dealer'].setValue(
                          this.Dealerships[0].DealershipID_PK
                        );
                      }
                    }

                  });
                } else {
                  this.apiservice
                  .get(
                    'Dealerships',
                    {
                      PageSize: 9999,
                      PageNumber: 1,
                      OrderBy: '',
                      SiteID_FK: this.site.SiteID_PK,
                    },
                    true
                  )
                  .then((x: any) => {
                    this.Dealerships = x.body;
                    if (this.Data.Type.toLowerCase() == 'new') {
                      this.Dealerships = this.Dealerships.filter((d:any) => d.NewDealer == true);
                    }
                    if (this.Data.Type.toLowerCase() == 'used') {
                      this.Dealerships = this.Dealerships.filter((d:any) => d.UsedDealer == true);
                    }
                    if (this.Data.Type.toLowerCase() == 'demo') {
                      this.Dealerships = this.Dealerships.filter((d:any) => d.UsedDealer == true);
                    }
                    if (this.Dealerships.length == 1) {
                      this.formEnquire.controls['dealer'].setValue(
                        this.Dealerships[0].DealershipID_PK
                      );
                    }
                    this.Dealerships.forEach((x: any) => {
                      if (
                        this.Regions.findIndex(
                          (y: any) => y.RegionID_PK == x.RegionID_FK
                        ) < 0
                      )
                        this.Regions.push({
                          Name: x.Region,
                          RegionID_PK: x.RegionID_FK,
                        });
                    });
                    this.Regions.sort((a: any, b: any) => {
                      if (a.Name < b.Name) {
                        return -1;
                      }
                      if (a.Name > b.Name) {
                        return 1;
                      }
                      return 0;
                    });
                  });
                }
              });
            });}
          });
          //}
        })
      }
      else {
        this.apiservice
          .get(
            'Dealerships',
            {
              PageSize: 9999,
              PageNumber: 1,
              OrderBy: '',
              SiteID_FK: this.site.SiteID_PK,
            },
            true
          )
          .then((x: any) => {
            this.Dealerships = x.body;
            var type: string = this.Data.Type;
            if (type.toLowerCase() == 'new') {
              this.Dealerships = this.Dealerships.filter((d: any) => d.NewDealer == true);
            }
            if (type.toLowerCase() == 'used') {
              this.Dealerships = this.Dealerships.filter((d: any) => d.UsedDealer == true);
            }
            if (type.toLowerCase() == 'demo') {
              this.Dealerships = this.Dealerships.filter((d: any) => d.UsedDealer == true);
            }
            if (this.Dealerships.length == 1) {
              this.formEnquire.controls['dealer'].setValue(
                this.Dealerships[0].DealershipID_PK
              );
            }
            this.Dealerships.forEach((x: any) => {
              if (
                this.Regions.findIndex(
                  (y: any) => y.RegionID_PK == x.RegionID_FK
                ) < 0
              )
                this.Regions.push({
                  Name: x.Region,
                  RegionID_PK: x.RegionID_FK,
                });
            });
            this.Regions.sort((a: any, b: any) => {
              if (a.Name < b.Name) {
                return -1;
              }
              if (a.Name > b.Name) {
                return 1;
              }
              return 0;
            });
          });
      }

    } else {
      this.formEnquire.controls['dealer'].setValue(this.Data.Dealer);
    }

    this.apiservice
      .get(
        'EnquiryTypes',
        {
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: '',
          SiteID_FK: this.site.SiteID_PK,
        },
        true
      )
      .then((x: any) => {
        this.EnquiryTypes = x.body;
      });

    if (this.Data.Type == 'specials') {
      this.Data.Type = 'special';
    }

  }

  submit() {
    if (this.formEnquire.invalid) {
      this.failedsubmit = true;
    } else {
      var GAData = {
        event: 'lead_submit',
        lead_type: 'automotive',
        lead_source: 'website',
        lead_first_name: this.formEnquire.controls['name'].value,
        lead_last_name: this.formEnquire.controls['surname'].value,
        lead_email: this.formEnquire.controls['email'].value,
        lead_phone: this.formEnquire.controls['phone'].value,
        lead_comments: this.formEnquire.controls['notes'].value || '',
      };

      try {
        window.dataLayer.push(GAData);
        sessionStorage.setItem('GAData', JSON.stringify(GAData));
      } catch (e) { }

      var GUID = (this.generateGUID()) + '-T' + Date.now();
      let script = this.renderer.createElement('script');
      script.text = "fbq('track', 'Lead', {currency: " + this.currencyCode + ", value: " + this.Data.Price + "}, {eventID:\"" + GUID + "\" });";

      this.renderer.appendChild(this._document.body, script);


      this.submitloading = true;

      var post = {
        Active: 1,
        ContactNo: this.formEnquire.controls['phone'].value,
        DealershipID_FK: this.formEnquire.controls['dealer'].value,
        Deleted: 0,
        Email: this.formEnquire.controls['email'].value,
        Marketing: this.formEnquire.controls['marketing'].value,
        Name: this.formEnquire.controls['name'].value,
        Notes: this.formEnquire.controls['notes'].value || '',
        SiteID_FK: this.site.SiteID_PK,
        TitleID_FK: this.formEnquire.controls['title'].value,
        Surname: this.formEnquire.controls['surname'].value,
        UtmCampaign: this.campaignService.getGoogleTracking(),
        VehicleModelID_FK: null,
        SpecialID_FK: null,
        VehicleStockID_FK: null,
        EnquiryTypeID_FK: this.EnquiryTypes.find(
          (x: any) => x.Code == 'Vehicle'
        ).EnquiryTypeID_PK,
        ModelDescription: this.Data.Name,
        LinkURL: this.Data.GenURL ? this.genURL() : window.location.href,
        FacebookFBP: this.getCookie('_fbp'),
        FacebookFBC: this.getCookie('_fbc'),
        FacebookFBE: GUID,
      };

      if (this.Data.Type.toLowerCase() == 'new') {
        post.VehicleModelID_FK = this.Data.ID;
      }
      if (this.Data.Type.toLowerCase() == 'special') {
        post.SpecialID_FK = this.Data.ID;
        post.EnquiryTypeID_FK = this.EnquiryTypes.find(
          (x: any) => x.Code == 'Specials'
        ).EnquiryTypeID_PK;
      }
      if (this.Data.Type.toLowerCase() == 'callback') {
        post.EnquiryTypeID_FK = this.EnquiryTypes.find(
          (x: any) => x.Code == 'ContactUs'
        ).EnquiryTypeID_PK;
      }
      if (this.Data.Type.toLowerCase() == 'used') {
        post.VehicleStockID_FK = this.Data.ID;
        post.EnquiryTypeID_FK = this.EnquiryTypes.find(
          (x: any) => x.Code == 'Pre-Owned'
        ).EnquiryTypeID_PK;
      }
      if (this.Data.Type.toLowerCase() == 'demo') {
        post.VehicleStockID_FK = this.Data.ID;
        post.EnquiryTypeID_FK = this.EnquiryTypes.find(
          (x: any) => x.Code == 'Pre-Owned'
        ).EnquiryTypeID_PK;
      }
      if (this.showDepartment) {
        post.EnquiryTypeID_FK = this.EnquiryTypes.find(
          (x: any) => x.Code == 'ContactUs'
        ).EnquiryTypeID_PK;
        if (this.formEnquire.controls['department'].value != null) {
          post.EnquiryTypeID_FK = this.EnquiryTypes.find(
            (x: any) => x.Code == this.formEnquire.controls['department'].value).EnquiryTypeID_PK;
        }
      }

      this.apiservice.post('Enquiries', post).then((x: any) => {
        if (x.EnquiryID_PK !== null) {
          window.location.href = '/' + this.Data.Type + '/thankyou';
        }
      });
    }
  }
  regionDealers(region: any) {
    return this.Dealerships.filter((x: any) => x.Region == region);
  }
  genURL() {
    if (this.Data.Type.toLowerCase() == 'new') {
      return window.location.protocol + '//' + window.location.host + '/buy/new/' + this.Data.ID + '/' + this.Data.Name;
    }
    if (this.Data.Type.toLowerCase() == 'special') {
      return window.location.protocol + '//' + window.location.host + '/buy/special/' + this.Data.ID + '/' + this.Data.Name;
    }
    if (this.Data.Type.toLowerCase() == 'used' || this.Data.Type.toLowerCase() == 'demo' || this.Data.Type.toLowerCase() == 'newstock') {
      return window.location.protocol + '//' + window.location.host + '/buy/stock/' + this.Data.ID + '/' + this.Data.Name;
    }
    return '';
  }
  getCookie(name: any) {
    var value = `; ${document.cookie}`;
    var parts!: any;
    parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    else return null;
  }


  generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  tcs() {
    this.modalService.open('tcs');
  }
}


