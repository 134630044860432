<nav class="navbar navbar-expand-lg navbar-light">
    <div class="logos">
        <a href="/" aria-label="Home"><img loading="lazy" [ngClass]="currentSite.layoutTonguelogo?'tonguelogo':'logo'" *ngIf="currentSite.Images" src="{{currentSite.Images[0]}}" alt=""></a>
        <img *ngIf="layout && currentSite.layoutBanner" loading="lazy" class="bidlogo" src="{{currentSite.layoutBanner.Value}}" alt="">
        <a aria-label="Burger Menu" id="menuToggle" data-bs-toggle="offcanvas" href="#offcanvasExample">
            <span></span>
            <span></span>
            <span></span>
          </a>
    </div>
    <div class="topnavbuttons" *ngIf="this.topnavbuttons">
      <div class="buttonpadding" *ngFor="let buttons of this.topnavbuttons">
        <ng-container *ngIf="buttons.name != 'contactus'"><a [ngClass]="buttons.name == this.taskservice.navbarbutton?'selected':''" href="{{buttons.url}}">{{buttons.text}}</a></ng-container>
        <ng-container *ngIf="!currentSite.dealerSite && buttons.name == 'contactus'"><a [ngClass]="buttons.name == this.taskservice.navbarbutton?'selected':''" href="/dealerships">Our Dealerships</a></ng-container>
        <ng-container *ngIf="currentSite.dealerSite && buttons.name == 'contactus'"><a [ngClass]="buttons.name == this.taskservice.navbarbutton?'selected':''" href="/dealership/{{currentSite.dealership.DealershipID_PK}}">Contact Us</a></ng-container>
      </div>
    </div>
    <a class="fav" *ngIf="!this.currentSite?.hideFavorites" aria-label="favourites" href="/favourites"><p class="d-none d-sm-block"><span [ngClass]="isfav()?'selected':''" class="icon-favourite"></span> My Favourites</p><p class="d-block d-sm-none"><span [ngClass]="isfav()?'selected':''" class="icon-favourite"></span></p></a>
</nav>

<div *ngIf="navbar[0]" class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Navigation</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
    <div class="offcanvas-body">
      <div  *ngFor="let pagepart of navbar[0]?.PageLayoutType">
        <div *ngIf="pagepart.PageLayoutTypeName == 'main'">
          <div *ngIf="currentSite.sidemenuStyle?.Value == 'icon'">
            <div class="col-12 hor text-center">
              <a *ngFor="let mainitem of pagepart.Value">
                <a href="/" *ngIf="mainitem.name == 'home'"><div class="text-center col-2_4"><span  class="icon-home"></span><p>{{mainitem.text}}</p></div></a>
                <a href="/buy" *ngIf="mainitem.name == 'buy'"><div class="text-center col-2_4"><span  class="icon-buy"></span><p>{{mainitem.text}}</p></div></a>
                <a href="/sell" *ngIf="mainitem.name == 'sell'"><div class="text-center col-2_4"><span  class="icon-sell"></span><p>{{mainitem.text}}</p></div></a>
                <a href="/service" *ngIf="mainitem.name == 'service'"><div class="text-center col-2_4"><span  class="icon-service"></span><p>{{mainitem.text}}</p></div></a>
                <a href="/parts" *ngIf="mainitem.name == 'parts'"><div class="text-center col-2_4"><span  class="icon-parts"></span><p>{{mainitem.text}}</p></div></a>
              </a>
            </div>
          </div>

          <div *ngIf="currentSite.sidemenuStyle?.Value == 'button'">
            <div class="col-12 row text-center">
              <div *ngFor="let mainitem of pagepart.Value" class="col-6 button-bottom">
                <a href="/" *ngIf="mainitem.name == 'home'"><div class="btn-primary text-center"><span class="icon-homenocircle btnlogo"></span><span class="btntext">{{mainitem.text}}</span></div></a>
                <a href="/buy" *ngIf="mainitem.name == 'buy'"><div class="btn-primary text-center"><span class="icon-buynocircle btnlogo"></span><span class="btntext">{{mainitem.text}}</span></div></a>
                <a href="/sell" *ngIf="mainitem.name == 'sell'"><div class="btn-primary text-center"><span class="icon-sellnocircle btnlogo"></span><span class="btntext">{{mainitem.text}}</span></div></a>
                <a href="/service" *ngIf="mainitem.name == 'service'"><div class="btn-primary text-center"><span class="icon-specialsservice btnlogo"></span><span class="btntext">{{mainitem.text}}</span></div></a>
                <a href="/parts" *ngIf="mainitem.name == 'parts'"><div class="btn-primary text-center"><span class="icon-specialsparts btnlogo"></span><span class="btntext">{{mainitem.text}}</span></div></a>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="pagepart.PageLayoutTypeName == 'sidemenu'">
          <div class="col-12 ver">
            <ul *ngFor="let sideitem of pagepart.Value">
              <li *ngIf="!currentSite.dealerSite && sideitem.name == 'dealership'"><a class="dropdown-item" href="/dealerships"><span  class="icon-dealers"></span><p>Our Dealerships</p></a></li>
              <li *ngIf="currentSite.dealerSite && sideitem.name == 'dealership'"><a class="dropdown-item" href="/dealership/{{currentSite.dealership.DealershipID_PK}}"><span  class="icon-dealers"></span><p>Contact Us</p></a></li>
              <li *ngIf="sideitem.name == 'new'"><a class="dropdown-item" href="/buy?type=new"><span  class="icon-new"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'used'"><a class="dropdown-item" href="/buy?type=used"><span  class="icon-used"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'demo'"><a class="dropdown-item" href="/buy?type=demo"><span  class="icon-demo"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'specials' && this.currentSite.NumberOfSpecials > 0"><a class="dropdown-item" href="/buy?type=specials"><span  class="icon-specials"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'news'"><a class="dropdown-item" href="/news"><span  class="icon-news"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'finance'"><a class="dropdown-item" href="/financeapplication"><span  class="icon-finance"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'parts'"><a class="dropdown-item" href="/parts"><span  class="icon-specialsparts"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="!currentSite.dealerSite && sideitem.name == 'contactus'"><a class="dropdown-item" (click)="enquire()" data-bs-dismiss="offcanvas"><span  class="icon-phone"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'fleetcontactus'"><a class="dropdown-item" href="/fleetcontactus"><span  class="icon-fleet"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'newstock'"><a class="dropdown-item" href="/buy?type=newstock"><span  class="icon-new"></span><p>{{sideitem.text}}</p></a></li>
              <!-- <li *ngIf="sideitem.name == 'testdrive'"><a class="dropdown-item" href="/testdrive"><span  class="icon-testdrive"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'accessories'"><a class="dropdown-item" href="/accessories"><span  class="icon-accessories"></span><p>{{sideitem.text}}</p></a></li>
              <li *ngIf="sideitem.name == 'whyservice'"><a class="dropdown-item" href="/whyservice"><span  class="icon-whyservice"></span><p>{{sideitem.text}}</p></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
</div>
